/*=========================================================
///////////////////////////////////////////////////////////
  BREAKPOINTS
///////////////////////////////////////////////////////////
=========================================================*/
$xs: 1px;   //or 320px
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
/*=========================================================
///////////////////////////////////////////////////////////
  MOBILE -- FIRST
///////////////////////////////////////////////////////////
=========================================================*/
   
  /* 1 & above --> xs*/
  /* 576 & above --> sm*/
  /* 768 & above --> md*/
  /* 992 & above --> lg*/
  /* 1200 & above --> xl*/
/*================================================
!!!!!!!!!!Syntax of myxin!!!!!!!!!!!!!!!
  @include breakpoint(sm) {
    color: red;
  }
*/
/*=======================================================================
/////////////////////////////////////////////////////////////////////////
                            ONLY
  xs-only sm-only md-only lg-only xl-only 
/////////////////////////////////////////////////////////////////////////
=======================================================================*/
  
  /* 1   - 576 --> xs-only*/
  /* 576 - 768 --> sm-only*/
  /* 768 - 992 --> md-only*/
  /* 992 - 1200 --> lg-only*/
  /* 1200 & above --> xl-only*/
/*================================================
!!!!!!!!!!Syntax of myxin!!!!!!!!!!!!!!!
  @include breakpoint(sm-only) {
    padding: 20px;
  }
*/
@mixin breakpoint($range) {
/*======================+MOBILE-FIRST+==========================================*/
  @if $range == xs {@media (min-width: ($xs)) { @content; }}
  @else if $range == sm {@media (min-width: $sm) { @content; }}
  @else if $range == md {@media (min-width: $md) { @content; }}
  @else if $range == lg {@media (min-width: $lg) { @content; }}
  @else if $range == xl {@media (min-width: $xl) { @content; }}   
/*=======================+ONLY+==================================================*/
  @else if $range == xs-only {@media screen and (min-width: $xs) and (max-width: ($sm - 1)) { @content; }}
  @else if $range == sm-only {@media screen and (min-width: $sm) and (max-width: ($md - 1)) { @content; }}
  @else if $range == md-only {@media screen and (min-width: $md) and (max-width: ($lg - 1)) { @content; }}
  @else if $range == lg-only {@media screen and (min-width: $lg) and (max-width: ($xl - 1)) { @content; }}
  @else if $range == xl-only {@media screen and (min-width: $xl) { @content; }} 
/*=======================+DOWN+==================================================*/
  @else if $range == xs-down {@media screen and (max-width: ($sm - 1)) { @content; }}
  @else if $range == sm-down {@media screen and (max-width: ($md - 1)) { @content; }}
  @else if $range == md-down {@media screen and (max-width: ($lg - 1)) { @content; }}
  @else if $range == lg-down {@media screen and (max-width: ($xl - 1)) { @content; }}  
}