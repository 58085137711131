.select-container {
  height: 32px !important;
  div[class$="-control"] {
    height: 32px !important;
    min-height: 32px !important;
    box-shadow: none !important;
    border: solid 1px #cecece !important;
    border-radius: 10px;
    span[class$="-indicatorSeparator"] {
      display: none;
    }
    div[class$="-singleValue"] {
      font-size: 12px;
      margin-top: -2px;
    }
    div[class$="-indicatorContainer"] svg {
      transform: scale(0.6);
      fill: black;
      margin-top: -2px;
    }
  }
  div[class$="-menu"] {
    border-radius: 10px !important;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0px 2px 6px 4px hsla(0, 0%, 0%, 0.1) !important;
  }
  div[class$="-MenuList"] {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  div[class$="-option"] {
    padding: 10px 12px;
  }
}

.Home {
  .loading-text,
  .no-results {
    font-size: 26px;
    margin-top: -20px;
    &:nth-child(3) {
      //if any items exist on page
      height: 68px; //button height
      margin-top: 30px; //20 margin-top of button + center of 48px button
    }
    span {
      font-family: sans-serif;
    }
  }
  @media (max-width: 575px) {
    //mobile
    margin-top: 16px;
  }
  // @media (min-width: 576px) {
  //   .no-results {
  //   }
  // }
  .row {
    &:first-of-type {
      flex-direction: column-reverse;
      margin-bottom: 20px;
      .select-container {
        margin-left: auto;
        margin-bottom: 15px;
        min-width: 195px;
        width: 195px;
      }
      @media (min-width: 768px) {
        // margin-bottom: 50px;
        flex-direction: row;
        .select-container {
          margin-bottom: 0;
          min-width: 210px;
          width: 210px;
        }
      }
      @media (max-width: 767px) {
        .select-container {
          position: absolute;
          margin-bottom: 75px;
          margin-top: -75px;
        }
        
      }
    }
    display: flex;
    justify-content: space-between;
    .select-container {
      display: flex;
      > span {
        white-space: nowrap;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 12px;
        margin-right: 8px;
        // display: inline;
      }
      > div {
        width: 100%;
        // display: inline-block;
      }
    }
  }
  .item-filter-container {
    &.filter-children-opened {
      margin-bottom: 40px;
    }
    .filter-description {
      margin-top: 16px;
      font-size: 14.5px;
      margin-bottom: 16px;
    }
    .item-filter-wrapper {
      @media (min-width: 576px) {
        // desktop
        margin-right: 16px;
      }
      display: flex;

      .filter-option-container {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
      @media (max-width: 575px) {
        //mobile
        margin-top: 16px;
        flex-direction: column;
        .selected-filter {

        margin-bottom: 95px;
        .filter-option-container {
          &:not(:last-child) {
            margin-right: 10px;
          }
          button {
            padding-inline: 12px;
          }
        }
      }
      }
      .items-count {
        font-size: 12px;
        margin-bottom: 16px;
        @media (min-width: 576px) {
          // desktop
          margin-bottom: auto;
          align-self: center;
          margin-left: 16px;
          margin-top: 10px;
        }
      }
      > *:not(:last-child) {
        &.item-filter {
          margin-bottom: 8px;
        }
      }
    }
    .item-filter button {
      font-size: 12px;
      padding: 9px 16px;
      width: auto !important;
    }
  }
  .load-more-button {
    border-radius: 18px;
    height: 48px;
    font-size: 14px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.text {
  h1 {
    font-family: $global-font-family;
    font-size: 32px;
    @include breakpoint(sm-down) {
      font-size: 22px;
    }
    font-weight: lighter;
    line-height: 32px;
    letter-spacing: 0.3px;
    color: #131413;
    margin-bottom: 20px;
  }
  p {
    font-family: $global-font-family;
    font-size: 32px;
    line-height: 42px;
    @include breakpoint(sm-down) {
      font-size: 22px;
      line-height: 32px;
    }

    letter-spacing: 0.3px;
    color: #131413;
    max-width: 915px;
    a {
      text-decoration: none;
      border-bottom: 2px solid #000000;
      color: #000000;
      &:hover {
        color: $link-hover-color;
        border-bottom: 2px solid $link-hover-color;
      }
    }
  }
  strong,
  b {
    font-weight: 500;
  }
}

.item-filter div.selected-filter .toggle-button {
  button {
    border: 1px solid black;
    color: black;
    &:focus,
    &:hover,
    &:active {
      color: white;
    }
  }
  &.filter-option-container-selected button {
    border: 1px solid black;
    color: white;
    background: black;
    &:focus,
    &:hover,
    &:active {
      color: white;
      svg {
        fill: black !important;
      }
    }
    svg {
      fill: white !important;
    }
  }
}
