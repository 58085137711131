/*=========================================================
///////////////////////////////////////////////////////////
  01 OUTLINE
///////////////////////////////////////////////////////////
=========================================================*/

@mixin outline($outline) {
  outline: $outline;
	outline-offset: $outline;
	:active, :hover, :focus {
	    outline: $outline;
	    outline-offset: $outline;
	}		
}
//Example
//@include outline(0);