input{
	color: $text-color;
}
input::-webkit-input-placeholder {
	color: $placeholder-color !important;
}	 
input:-moz-placeholder { /* Firefox 18- */
	color: $placeholder-color !important;  
}	 
input::-moz-placeholder {  /* Firefox 19+ */
	color: $placeholder-color !important;  
}	 
input:-ms-input-placeholder {  
	color: $placeholder-color !important;  
}
textarea::-webkit-input-placeholder {
	color: $placeholder-color !important;
}	 
textarea:-moz-placeholder { /* Firefox 18- */
	color: $placeholder-color !important;  
}	 
textarea::-moz-placeholder {  /* Firefox 19+ */
	color: $placeholder-color !important;  
}	 
textarea:-ms-input-placeholder {  
	color: $placeholder-color !important;  
}