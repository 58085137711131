/*=========================================================
///////////////////////////////////////////////////////////
	COLORS
///////////////////////////////////////////////////////////
=========================================================*/
$black: #000000;
$white: #ffffff;
$gray: #3d3d3d;

$placeholder-color: #a8a9a9;
$blue: #3489ff;

$text-color: $black;
$link-hover-color: rgba(0, 0, 0, 0.8);
$heading-color: $black;
// $placeholder-color: #cecece; //f00;

/*=========================================================
///////////////////////////////////////////////////////////
	FONTS
///////////////////////////////////////////////////////////
=========================================================*/

$global-font-family: Inter, sans-serif;
$secondary-font-family: Inter, sans-serif;
$heading-font-family: Inter, sans-serif;
$button-font-family: Inter, sans-serif;

/*=========================================================
///////////////////////////////////////////////////////////
  Layouts 
///////////////////////////////////////////////////////////
=========================================================*/
$body-background-color: #fff;

$max-content-width: 1384px;
:root {
  --horizontal-padding: 16px;
  --content-check-icon: url('data:image/svg+xml; utf8,<svg xmlns="http://www.w3.org/2000/svg"  xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="13px" height="11px" viewBox="0 0 448.8 448.8" xml:space="preserve"><g>	<g id="check">		<polygon points="142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55   "/>	</g></g></svg>');
  @include breakpoint(md) {
    --horizontal-padding: 80px;
  }
}

$height-photo-modal-header: 100px;
$modal-z-index: 500;
